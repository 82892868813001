import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Back to `}<a parentName="p" {...{
        "href": "/Case-studies",
        "title": "Case-studies"
      }}>{`Case-studies`}</a>{``}</p>
    <h3>{`Taiwan's Public Digital Innovation Space`}</h3>
    <p><strong parentName="p">{`Overview:`}</strong>{` `}</p>
    <h3>{`Links`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://pdis.nat.gov.tw/en/"
        }}>{`https://pdis.nat.gov.tw/en/`}</a></li>
      <li parentName="ul">{`PDIS Public Digital Innovation Space write-up on a 2019 Polis engagement:   `}<a parentName="li" {...{
          "href": "https://pdis.nat.gov.tw/en/blog/%E5%A6%82%E4%BD%95%E9%81%8B%E7%94%A8%E8%A8%AD%E8%A8%88%E6%80%9D%E8%80%83%E9%80%B2%E8%A1%8C%E6%94%BF%E5%BA%9C%E6%95%B8%E4%BD%8D%E6%9C%8D%E5%8B%99%E7%9A%84%E8%A8%AD%E8%A8%88/"
        }}>{`https://pdis.nat.gov.tw/en/blog/%E5%A6%82%E4%BD%95%E9%81%8B%E7%94%A8%E8%A8%AD%E8%A8%88%E6%80%9D%E8%80%83%E9%80%B2%E8%A1%8C%E6%94%BF%E5%BA%9C%E6%95%B8%E4%BD%8D%E6%9C%8D%E5%8B%99%E7%9A%84%E8%A8%AD%E8%A8%88/`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      